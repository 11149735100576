<style lang="scss" scoped>
    // 验证框
    .verification {
        width: 548px;
        background: #fff;
        box-shadow: 0px 0px 24px 0px rgb(0 0 0 / 20%);
        border-radius: 10px;
        margin-top: -22px;
        box-sizing: border-box;
        padding: 14px 24px 30px 24px;
        position: absolute;
        top: -40px;

        .verification-title {
            color: #999;
            font-size: 16px;
            display: flex;
            padding: 16px 0;
            justify-content: space-between;
            align-items: center;

            .close {
                width: 25px;
                height: 25px;
                background: #cecece;
                text-align: center;
                line-height: 25px;
                border-radius: 50%;
                color: #fff;
            }
        }

        .tips {
            height: 281px;
            line-height: 281px;
        }
    }

    .verifybox {
        display: flex;
    }

    .forget-link {
        text-align: end;
        margin-top: 12px;
        font-size: 14px;
        color: #737373;
    }

    .login-container {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;

        .login-left-img {
            width: 670px;
            height: 100%;
            background: #8375e7;
            overflow: hidden;
            border-radius: 0vw 3.13vw 3.13vw 0vw;
            position: relative;

            img {
                position: absolute;
                bottom: 0;
                width: 670px;
                height: 100%;
                object-fit: cover;
            }
        }

        .login-right-content {
            height: 100%;
            flex: 1;
            min-width: 680px;
            position: relative;
            overflow: hidden;

            .content-box {
                width: 548px;
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .small-element-ruler {
                position: absolute;
                top: 245px;
                left: 49px;
            }
        }
    }

    .content-box {
        .login-logo {
            text-align: center;
        }

        .login-title {
            text-align: center;
            font-size: 36px;
            font-weight: 600;
            font-stretch: normal;
            line-height: 36px;
            letter-spacing: 0px;
            color: #3e3e3e;
            margin-top: 35px;
        }

        .login-describe {
            margin-top: 10px;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 35.86px;
            letter-spacing: 0px;
            color: #272727;
        }

        .login-form {
            margin-top: 60px;
            padding: 0 20px;
            position: relative;

            .small-element-block {
                position: absolute;
                top: 50px;
                left: -108px;
            }
        }
    }

    ::v-deep {
        .provinceList-box .el-form-item__content {
            display: flex;
            justify-content: space-between;
        }

        .provinceList-box .el-form-item__content .el-select {
            width: 165px;
        }

        // 验证码
        .drag-verify-container img {
            border-radius: 12px !important;
        }

        .tips {
            height: 281px !important;
            line-height: 281px !important;
        }

        .tips.danger {
            background: rgba($color: #ffffff, $alpha: 0.4) !important;
            color: #ff0728 !important;
            font-size: 16px !important;
            font-weight: 500 !important;
        }

        .tips.success {
            font-size: 16px !important;
            font-weight: 500 !important;
        }

        .drag_verify {
            height: 52px !important;
            line-height: 52px !important;
            margin-top: 16px !important;
        }

        .refresh {
            margin-top: 15px;
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            font-size: 16px !important;
            color: #fff;
            margin-right: 6px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.5);

            .iconfont {
                font-size: 16px !important;
            }
        }

        .dv_handler {
            width: 52px !important;
            height: 52px !important;
            background: rgb(255, 255, 255) !important;
            border-radius: 0px 12px 12px 0px !important;
        }

        .dv_text,
        .dv_progress_bar {
            height: 52px !important;
        }

        .el-input__inner {
            height: 60px;
            line-height: 60px;
            border: 1px solid #f6f7fa;
            background-color: #f6f7fa;
            border-radius: 10px;
        }

        .el-input__inner:focus {
            border: 1px solid #6c4ecb;
        }

        .el-form-item {
            margin-bottom: 20px;
        }

        .el-input__inner::placeholder {
            color: #a2a2a2;
        }

        .protocolTitle {
            text-align: center;
            margin-top: 40px;
            color: #666666;
            a {
                color: #01A1FD;
            }
        }
        .el-button--primary {
            font-weight: 600;
            height: 65px;
            font-size: 18px;
            border: none;
            background: #6c4ecb;
            margin-top: 20px;
            border-radius: 10px;
        }
    }
</style>

<template>
    <div class="login-container">
        <div class="login-left-img">
            <img src="../../assets/images/login-bg.png" alt=""/>
        </div>
        <div class="login-right-content">
            <div class="small-element-ruler">
                <img src="../../assets/images/small-element-ruler.png" alt=""/>
            </div>
            <div class="content-box">
                <div class="login-logo">
                    <img src="../../assets/images/logo.png" alt=""/>
                </div>
                <div class="login-title">欢迎登录</div>
                <p class="login-describe">育材地教育局端</p>
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on"
                         label-position="left">
                    <div class="small-element-block">
                        <img src="../../assets/images/small-element-block.png" alt=""/>
                    </div>

                    <el-form-item class="provinceList-box" v-loading="provinceLoading">
                        <el-select v-model="provinceValue" placeholder="请选择省"
                                   @change="changePosition($event, 'province')">
                            <el-option v-for="item in provinceList" :key="item.ddi_name" :label="item.ddi_name"
                                       :value="item.ddi_id">
                            </el-option>
                        </el-select>

                        <el-select v-model="cityValue" placeholder="请选择市" @change="changePosition($event, 'city')">
                            <el-option v-for="item in cityList" :key="item.ddi_name" :label="item.ddi_name"
                                       :value="item.ddi_id">
                            </el-option>
                        </el-select>

                        <el-select v-model="areaValue" placeholder="请选择区" @change="changePosition($event, 'area')">
                            <el-option v-for="item in areaList" :key="item.ddi_name" :label="item.ddi_name"
                                       :value="item.ddi_id">
                            </el-option>
                        </el-select>

                    </el-form-item>


                    <el-form-item prop="eduser_account">
                        <el-input ref="eduser_account" v-model="loginForm.eduser_account" placeholder="请输入账号"
                                  name="eduser_account"
                                  type="text" tabindex="1" autocomplete="on"/>
                    </el-form-item>

                    <el-form-item prop="eduser_password">
                        <el-input ref="eduser_password" v-model="loginForm.eduser_password" type="password"
                                  placeholder="请输入密码"
                                  name="eduser_password" tabindex="2" autocomplete="on"/>
                    </el-form-item>
                    <div class="protocolTitle">
                        登录即代表我已同意<a href="https://api.zytycd.com/protal/common/protocol?orgin=education&type=serve"
                                    target="_blank">《用户服务协议》</a>和 <a
                            href="https://api.zytycd.com/protal/common/protocol?orgin=education&type=privacy"
                            target="_blank">《隐私政策》</a>
                    </div>
                    <el-button :loading="loading" type="primary" style="width: 100%"
                               @click.native.prevent="handleLogin">登
                        录
                    </el-button>
                    <!-- <div class="forget-link">
                      <el-button
                        :loading="loading"
                        type="text"
                        @click.native.prevent="handleForget"
                        >忘记密码</el-button
                      >
                    </div> -->

                    <div class="verification" v-if="isCode">
                        <div class="verification-title">
                            <span>请完成安全验证码</span>

                            <span class="close">
                <i class="iconfont icon-guanbi" @click="isCode = false"></i>
              </span>
                        </div>

                        <drag-verify-img-chip ref="dragVerify" :imgsrc="imgsrc" :isPassing.sync="isPassing"
                                              :showRefresh="true"
                                              refreshIcon="iconfont icon-shuaxin" :barWidth="52" :width="500"
                                              :barRadius="12" textColor="#888"
                                              text="请按住滑块拖动" successText="验证通过" handlerIcon="el-icon-d-arrow-right"
                                              successIcon="el-icon-circle-check"
                                              @refresh="reimg" @passcallback="pass">
                        </drag-verify-img-chip>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {login} from "@api/user";
    import {$getProvinceData} from "@/api/common"
    import dragVerifyImgChip from "vue-drag-verify-img-chip";
    // import provinces from "@/assets/data/provinces.json";
    export default {
        name: "Login",
        components: {},
        data() {
            return {
                loginForm: {
                    eduser_account: "",
                    eduser_password: "",
                },
                loginRules: {
                    eduser_account: [
                        {required: true, trigger: "blur", message: "请输入账号"},
                    ],
                    eduser_password: [
                        {required: true, trigger: "blur", message: "请输入密码"},
                    ],
                },
                newdate: "",
                loading: false,

                // 滑块验证
                isPassing: false,
                value: "",
                imgsrc: require("@/assets/images/codeimg/1.webp"),

                // 是否开启验证码
                isCode: false,
                //是否已经通过验证
                isPassCode: false,

                provinceList: [], // 省
                cityList: [], // 市
                areaList: [], // 区
                provinceValue: "",
                cityValue: "",
                areaValue: "",

                provinceLoading: false,  //加载

            };
        },
        components: {
            dragVerifyImgChip,
        },

        watch: {},

        created() {
            // let province = this._.groupBy(provinces, "type");
            // this.provinceList = province[0];
        },

        async mounted() {
            let provinceDataList = [];

            this.provinceLoading = true;
            let res = await $getProvinceData();
            if (res.status == 200) {
                this.provinceList = res.data;
            }
            this.provinceLoading = false;
            //eduser_account、eduser_password 为空时，获取焦点
            // if (this.loginForm.eduser_account == "") {
            //   this.$refs.eduser_account.focus();
            // } else if (this.loginForm.eduser_password == "") {
            //   this.$refs.eduser_password.focus();
            // }
            // 绑定enter事件
            this.enterKeyup();
        },

        methods: {

            enterKey(event) {
                const code = event.keyCode
                    ? event.keyCode
                    : event.which
                        ? event.which
                        : event.charCode;
                if (code == 13) {
                    this.handleLogin();
                }
            },
            enterKeyupDestroyed() {
                document.removeEventListener("keyup", this.enterKey);
            },
            destroyed() {
                // 销毁enter事件
                this.enterKeyupDestroyed();
            },
            enterKeyup() {
                document.addEventListener("keyup", this.enterKey);
            },
            /**
             * 地址选择
             * **/
            changePosition(e, calssly, type) {
                /**
                 * 使用本地存储的区域json
                 * **/
                // let province = this._.groupBy(provinces, "type");
                // switch (calssly) {
                //   case 'province':
                //     this.cityList = [];
                //     this.areaList = [];
                //     this.cityValue = "";
                //     this.areaValue = "";
                //     let cityLists = this._.groupBy(province[1], "parent_code");
                //     this.cityList = cityLists[e];
                //     break;
                //   case 'city':
                //     this.areaList = [];
                //     this.areaValue = "";
                //     let areaLists = this._.groupBy(province[2], "parent_code");
                //     this.areaList = areaLists[e];
                //     break;
                //   default:
                //     break;
                // }

                /**
                 * 使用线上数据
                 * **/
                switch (calssly) {
                    case 'province':
                        this.cityList = [];
                        this.areaList = [];
                        this.cityValue = "";
                        this.areaValue = "";
                        this.cityList = this.handlerrecursion(this.provinceList, e)
                        break;
                    case 'city':
                        this.areaList = [];
                        this.areaValue = "";
                        this.areaList = this.handlerrecursion(this.cityList, e)
                        break;
                    default:
                        break;
                }

            },

            /**
             * 递归查找id
             * **/
            handlerrecursion(arr, id) {
                for (let index = 0; index < arr.length; index++) {
                    if (arr[index].ddi_id == id) {
                        return arr[index].children
                    }
                }


            },

            /**
             * 用户登录
             * post 请求例子
             */
            handleLogin() {
                this.$refs.loginForm.validate(async (valid) => {
                    if (!valid) return false;

                    if (this.isPassCode == false) {
                        this.isCode = true;
                        return;
                    }
                    let params = {...this.loginForm, eduser_area: this.areaValue};

                    const resData = await login(params);

                    if (resData.status != 200) {
                        this.isPassCode = false;
                        this.iscode = false;
                        this.$notify({
                            title: "登录失败",
                            message: resData.msg,
                            type: "error",
                            duration: 1000,
                        });
                        this.isPassing = false;
                        return;
                    }

                    sessionStorage.setItem("Token", resData.data.token);
                    sessionStorage.setItem("Userinfo", JSON.stringify(resData.data.userinfo));
                    if (resData.data.check_data.isupdatepwd != 1) {
                        sessionStorage.removeItem("updatePwd")
                        this.$notify({
                            title: "登录成功",
                            message: "",
                            type: "success",
                            duration: 1000,
                        });
                        this.$router.push(`/home?redirect=${this.$route.fullPath}`);
                    } else {
                        this.$message.warning("为了您的账户安全，请修改密码");
                        this.$router.replace({name: "SYSTEM_PERSONAL"});
                        setTimeout(() => {
                            sessionStorage.setItem("updatePwd", true);
                        }, 1500);
                    }


                    // this.$router.push({
                    //   name: "HOME",
                    // });
                });
            },

            handleForget() {
                this.$router.push({
                    name: "FORGOT",
                });
            },

            // 验证码
            passcallback() {
                // this.$message({
                //   message: "验证通过",
                //   type: "success",
                // });
            },
            reimg() {
                //刷新
                this.$refs.dragVerify.reset();

            },
            pass() {
                this.isPassCode = true;
                setTimeout(() => {
                    this.isCode = false;
                    this.handleLogin();
                }, 1200);
            },
            reset() {
                this.isPassing = false;
                this.$refs.dragVerify.reset();
            },
        },
    };
</script>
